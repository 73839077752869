.vision-top {
  display: none;
}

@media screen and (max-width: 680px) {
  .pricing-main-c2 h3 {
    font-size: 40px;
    margin-top: "20px";
  }
  .my-lbutton {
    padding: 5px 10px;
  }
  .hover-card-b{
    padding: 7px 15px;

  }
  .vision-left {
    display: none;
  }
  .vision-page-con {
    flex-direction: column;
  }
  .vision-top {
    display: flex;
  }
  .roadm-t-div {
    margin-top: 0px;
  }
  .header-cards-btn {
    padding-top: 0px;
  }
  .vision-right-bx2{
    padding: 20px 20px;
  }
  .simu-main-cards-c .ant-row{
    min-width: 100%;
  }
}
@media screen and (min-width: 680px) {
}
