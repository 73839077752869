.vision-page-con {
  display: flex;
}
.vision-left {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 20px;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  /* background-color: #1a1a1a; */
}
.vision-left2 {
  display: flex;
  flex-direction: column;

  align-items: center;
}
.vision-left .hover-card-b {
  margin-top: 20px;
}

.vision-right-bx {
  width: 100%;

  height: 100vh;
}
.status-bx-invt {
  margin-bottom: 10px;
}
.vision-right-bx2 {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  color: white;
  padding: 20px 50px;
  /* background-color: black; */
}
.roadm-t-div {
  color: white;
  margin-top: 20px;
  display: flex;

  justify-content: center;
}
.play-pause-svg {
  font-size: 20px;
  color: black;
  cursor: pointer;
  border-radius: 50%;
}
.play-pause-svg:hover {
  background-color: white;
  transition: 0.4s;
}
.yellow-pl {
  background-color: rgb(255, 255, 74);
  background: linear-gradient(-45deg, #bedbff 0%, #7eb0ec 100%);
}

.green-pl {
  background-color: lime;
  /* color: black; */
}
.main-yello-c {
  color: black;
  font-size: 33px;
}

.notifications-container {
  width: 420px;
  height: auto;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.flex {
  display: flex;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.dad-joke {
  padding: 1rem;
  border-radius: 0.375rem;
  background-color: rgb(248, 231, 28);
}

.dad-joke-svg {
  color: #1a202c;
  width: 1.25rem;
  height: 1.25rem;
}

.dad-joke-prompt-wrap {
  margin-left: 0.75rem;
}

.dad-joke-prompt-heading {
  font-weight: bold;
  color: rgb(57, 55, 54);
}

.dad-joke-prompt {
  margin-top: 0.5rem;
  color: rgb(57, 55, 54);
}

.dad-joke-button-container {
  display: flex;
  margin-top: 0.875rem;
  margin-bottom: -0.375rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.dad-joke-button-main {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #175100;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
  border-radius: 0.375rem;
  border: none;
}

.dad-joke-button-main:hover {
  background-color: #258002;
}
.center-alert-c {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}
.vision-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.top-header-icns {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.top-header-icns .hover-card-b {
  margin: 0px 5px;
  padding: 0px;
  box-shadow: 0px 0px #bedbff;
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.top-header-icns .hover-card-b svg {
  font-size: 20px;
}
.roadm-t-div .ant-dropdown-trigger {
  display: flex;
  align-items: center;
}
.selected-crd.hover-card-b {
  border: 1px solid #bedbff;
  box-shadow: 4px 5px black;
  color: black;
  background: linear-gradient(-45deg, #8bbffe 0%, #c9fadb 100%);}
.c3-svg.show-hover{
  right: 10px;
  position: absolute;
  top: 10px;
  z-index: 20;
  cursor: pointer;
}
.c3-card:hover .c3-svg.show-hover{
  display: inline;
}
.c3-svg.show-hover:hover{
transform: scale(1.1);
transition: 0.4s;
}
.ins-height-sc{
  height: 100%;
  padding-bottom: 60px;
  overflow-y: auto;
}
