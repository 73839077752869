:root {
  --blue1: #e3f2fd;
  --bgBlack2: #ffffff14;
  --blue2: rgb(41 62 109);
}
.home-input-c-c {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: 20px;
  margin: 20px 20px 0px 20px;
  box-shadow: 3px 4px #000;
  border: 1px solid #000;
  background-color: rgb(249, 226, 143);

}
.home-input-c-c > div {
  display: flex;
  /* justify-content: center; */
}
.margin-card-hover .hover-card-b{
  margin: 0px 5px;
}
.parameters-d-cli {
  display: flex !important;
  align-items: flex-start;
  justify-content: space-between !important;
  width: 100%;
}
.parameters-d-cli .close-icon {
  width: auto;
}
.home-input-c-c > img {
  margin-top: 20px;
  width: 66px;
  color: var(--blue2);
  margin-bottom: -10px;
  /* background-color: white; */
  padding: 3px;
  border-radius: 3px;
}
.signup-main-p {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.gi-2x {
  font-size: 2em;
}
.gi-3x {
  font-size: 3em;
}
.gi-4x {
  font-size: 4em;
}
.gi-5x {
  font-size: 5em;
}

.landing-page-container .btn {
  border-width: 0;
  border-radius: 3px;
  color: #2b3335;
}

.landing-page-container .btn-a {
  background-color: #cfa9af;
}

.landing-page-container .btn-b {
  background-color: #ac9785;
}

.landing-page-container .btn-c {
  background-color: #4e5356;
}

.landing-page-container .btn-c span,
.landing-page-container .btn-c {
  color: #c5b1a7;
  font-weight: normal;
  font-size: 1em;
  line-height: 1em;
  display: inline-block;
  vertical-align: middle;
}

.landing-page-container section {
  padding: 150px 0;
  color: #2b3335;
}

.landing-page-container .section-services {
  background: #ebedec;
}

.landing-page-container .section-contact {
  background: #c5b1a7;
}

.landing-page-container .section-contact a {
  color: #2b3335;
  font-size: 1.5em;
}

.landing-page-container .font-weight-normal {
  font-weight: normal;
}

.landing-page-container .font-weight-bold {
  font-weight: bold;
}

.landing-page-container section.jumbotron {
  background-image: url(http://previews.123rf.com/images/rido/rido1506/rido150600049/41262919-Closeup-shot-of-laptop-with-digitaltablet-and-smartphone-on-desk-Modern-devices-on-desk-at-office-Th-Stock-Photo.jpg);
  background-size: cover;
  background-position: center center;
  color: white;
  position: relative;
  margin: 0;
}

.landing-page-container section.jumbotron .container {
  padding: 200px 0;
}

.landing-page-container section.jumbotron:before {
  content: "";
  position: absolute;
  opacity: 0.5;
  background: #000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.landing-page-container section.jumbotron .container {
  position: relative;
}
.create-input-m {
  display: flex;
  align-items: flex-end;
  margin: 20px 0px;
  position: relative;
}
.create-input-m .ant-input {
  width: 300px;
  margin-right: 10px;
  padding-right: 30px;
}
.grear-icon-s {
  position: absolute;
  right: 15px;
  top: 15px;
  /* color: white; */
  z-index: 30;
  cursor: pointer;
}
.grear-icon-s:hover {
  transform: scale(1.1);
  transition: 0.4s;
}

.behaviour-cards .ant-typography p {
  margin-bottom: 0px;
}
.click-icon-bx {
  position: absolute;
  right: 15px;
  bottom: 5px;
}
.click-icon-bx:hover {
  /* color: var(--blue2); */
  transform: scale(1.1);
  transition: 0.4s;
  cursor: pointer;
}
.tabs-bx-mn-h {
  display: flex;
  justify-content: center;
}
.tabs-bx-mn-h .behaviour-cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-loading {
  height: 300px;
}
.tags-date-area td h6 {
  color: white;
}
.tags-date-area.right-area-det-m {
  display: flex;
  justify-content: center;
  width: 100%;
}
.logo-upload-img-style {
  height: 150px;
  border-radius: 5px;
}
.edit-close-icon-img {
  position: absolute;
  z-index: 15;
  color: white;
  top: 5px;
  right: 5px;
}
.image-main-td-tb {
  display: flex;
}
.edit-close-icon-img svg {
  background-color: rgba(0, 0, 0, 0.7);
  margin: 5px;
  padding: 3px;
  font-size: 20px;
  border-radius: 3px;
  display: none;
  cursor: pointer;
}
.tags-date-area.right-area-det-m .ant-upload {
  color: white;
}
.image-main-td-tb > div:hover .edit-close-icon-img svg {
  display: inline;
}
.application-div .behaviour-cards {
  background: linear-gradient(to bottom, rgb(198 233 255), rgb(116 187 233));
  color: black;
}
.application-div .behaviour-cards:hover {
  background: linear-gradient(
    to bottom,
    rgb(198 233 255),
    rgb(116 187 233),
    rgb(198 233 255)
  );
  transition: all 0.5s ease-in-out;
}
.home-filters-con {
  display: flex;
}
.home-filters-con b {
  display: flex;
  margin: 0px 10px;
  align-items: center;

  font-size: 12px;
  color: #4e5356;
}
.imgs-attached-con {
  display: flex;
}
.imgs-attached-con img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 5px;
  margin: 10px;
  border: 2px solid white;
  background-color: #ac9785;
}
.icons-click-upload {
  display: flex;
  margin-left: 10px;
}
.icons-click-upload img {
  width: 32px;
  margin: 0px 5px;
}
.icons-click-upload img:hover {
  cursor: pointer;
}
.icons-click-upload .anticon.anticon-loading {
  position: absolute;
  margin-top: -15px;
  margin-left: 5px;
}
.save-btn-cancel button {
  margin-left: 15px;
}

.dynamic-icon-c {
  font-size: 24px;
  margin-right: 10px;
}
.dynamic-icon-c svg {
  margin-top: -10px;
}
.main-container-edit {
  border: 2px solid rgb(140, 140, 140);
  border-radius: 5px;
  background-color: white;
}
.function-card-c {
  display: flex;
  align-items: center;
  padding: 10px;

  background-color: white;
  border: 1px solid black;
  position: relative;
}

.function-card-c:hover {
  transition: all 0.4s ease-in-out;
  transform: scale(1.03);
  cursor: pointer;
}
.function-card-c.no-hover-card {
  transform: none;
  cursor: default;
}
.function-card-c img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 10px;
  border-radius: 3px;
}
.function-card-c .ant-typography {
  margin-bottom: 0px !important;
  font-size: 12px;
}
.function-card-c.active-card-sc {
  background-color: rgb(170, 252, 170);
  border-color: rgb(71, 249, 71);
}
.function-card-c:hover .show-hover {
  display: inline;
}
.delete-icon-ele {
  position: absolute;
  right: 0px;
  top: 0px;
}
.delete-icon-ele:hover {
  transition: all 0.4s ease-in-out;
  transform: scale(1.03);
  cursor: pointer;
  color: red;
}
.build-button-c {
  background-color: rgb(49, 250, 49);
}
.build-button-c:hover {
  border-color: rgb(49, 250, 49) !important;
  color: black !important;
}
.load-more-btn .ant-tag {
  cursor: pointer;
}
.back-icn {
  position: absolute;
  left: 20px;
  top: 20px;
}
.img-sel-elm {
  width: 30px;
  margin-right: 10px;
}
.nam-area-ley- {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid rgb(223, 223, 223);
  margin: 10px 0px;
}
.nam-area-ley- > div {
  display: flex;
}
.nam-area-ley- > div p {
  margin-bottom: 5px;
}
.nam-area-ley- img {
  width: 40px;
  margin-right: 10px;
  height: 40px;
  border-radius: 50%;
  background-color: #aaaaaa;
}
.nam-area-ley- svg {
  cursor: pointer;
}
.hover-card-b {
  color: #bedbff;
  text-transform: capitalize;
  background-color: transparent;
  border: 1px solid #bedbff;
  box-shadow: 3px 4px #bedbff;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.45s;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.hover-card-b:hover {
  box-shadow: none;
  cursor: pointer;
}
.header-cards-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0px 20px;
}
.header-cards-btn .hover-card-b {
 margin: 0px 10px 20px 10px;
}
.filter-box-r {
  display: flex;
}
.top-active-img {
  height: 45px;
  margin-right: 10px;
}
.role-play-molda-c {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.role-play-m1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.role-play-m1 b {
  color: gray;
}
.ant-card-bordered {
  border-color: #000;
  border-radius: 0px;
}
.vector-list-m {
  margin: 0 10%;
}
.assets-c-n-c .vector-list-m {
  margin: 0px;
  height: 100%;
}
.assets-c-n-c .ant-card-bordered {
  height: 100%;
  /* display: flex; */
}
.assets-c-n-c .ant-card-bordered .ant-card-body {
  height: 100%;
}
.assets-c-n-c .ant-card-bordered .ant-card-body > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.image-card-as-q {
  height: 48px;
  margin: 10px 10px;
  text-align: center;
}
.error-card {
  background: linear-gradient(-45deg, #ffaf97, #ff8eb9, #ffaf97, #ff8eb9);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
.progess-card {
  background: linear-gradient(-45deg, #feffec, #fcfed3, #feffec, #fcffc7);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
.complete-card {
  background: linear-gradient(-45deg, #f7fed9, #bbffaf, #f7fed9, #bbffaf);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.image-card-as img {
  width: 100%;
  max-height: 300px;
  object-fit: contain;
  /* background-color: #f3f3f3;  */
}
.icons-area-as-c {
  display: flex;
  justify-content: center;
  font-size: 30px;
  /* margin-bottom: 10px; */
}
.player-audio-bx {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.player-audio-bx .react-audio-player{
  background-color: #f2f3f4
}
.application-div21 {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.application-div21 .behavior-table-add{
  border: 1px solid black;
}
.dev-btn-t{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.domain-cards-bx{
  display: flex;
justify-content: center;
margin-top: 20px;
}
.domain-cards-bx .hover-card-b{
  margin: 0px 5px;
}
.runtime-amount{
  margin-left: 10px;
  color: rgb(99, 165, 0);
}
.guide-filters{
  margin: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.guide-filters .hover-card-b{
  margin: 5px;
}