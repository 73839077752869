.left-p-2 {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}
.left-p-21 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  border-radius: 5px;
  height: 100%;
}
.left-p-221 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
  border-radius: 5px;

  background-color: rgb(238, 245, 254);
}
.left-p-21 .hover-card-b.selected-crd {
  border-radius: 20px;
  background: linear-gradient(-45deg, #beffca 0%, #a7dfb1 100%);
}
.left-p-21 .hover-card-b.selected-crd:hover svg {
  margin-right: -5px;
  margin-left: 5px;
  transition: 0.5s;
}
.left-p2-2 {
  background-color: black;
  padding: 5px;
  border-radius: 5px;
  color: white;
  height: 100%;
}
.left-p2-221 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.left-p2-221 h5 {
  padding: 10px;
  font-size: 14px
  ;
  margin: 5px 0px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(26 32 44);
  border-radius: 5px;
}
.left-p2-2 .ant-row{
    height: 100%;
}
.pricing-main-c1{
    height: 85vh;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}
.pricing-main-c2{
    max-width: 1000px;
}
.pricing-main-c2 h3{
    color: white;
    margin-bottom: 20px;
    font-size: 50px;
}
.pricing-b-c a {
    color: white;
    text-decoration: none;
}
.pricing-b-c{
    margin-right: 30px;
}
.left-p-21 .my-lbutton{
  padding: 5px 10px;
}
