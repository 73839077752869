.my-llanding-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 100vh;
  padding: 20px;
  color: white;
  overflow-y: auto;
  /* background-image: url("../../images//background-land.png");
  background-size: cover; 
  background-position: center; */
  width: 100%;
}
.my-llanding-page .my-llanding-page2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 760px;
}

.my-lheading {
  font-size: 4rem;
  text-align: center;
  margin-top: 100px;
  color: white;
}

.my-lparagraph {
  margin-top: 20px;
  text-align: center;
  max-width: 500px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  color: black;
}

.my-lvideo-container {
  margin-top: 20px;
}

.popup-center-text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.popup-center-text .my-lbuttons {
  margin-top: 50px;
}
.popup-center-text .my-lheading {
  margin-top: -50px;
}
.my-lvideo {
  width: 700px;
  max-width: 800px;
  min-height: 300px;
}
.landing-logo-no-u {
  width: 100%;
  top: 0;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
}
.landing-logo-no-10 {
  background: linear-gradient(-45deg, #020c23, #020c23);
  padding: 10px 10px 10px 30px;
  border-radius: 0px;
}
.my-lbuttons {
  margin-top: 260px;
  width: 350px;
  display: flex;
  justify-content: space-around;
}
.text-sixe0card {
  font-size: 16px;
  font-weight: 500;
}
.card-vd-top-m h5 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}
.my-lbutton-10 {
  color: white;
  background-color: transparent;
}
.landing-logo-no-u a {
  text-decoration: none;
}
.my-lbutton-10:hover {
  color: #8bbffe;
}

.my-lbutton {
  padding: 10px 20px;
  font-size: 1rem;
  background-image: linear-gradient(90deg, #c9fadb, #8bbffe);
  color: BLACK;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 500;
}

.my-lbutton:last-child {
  margin-right: 0;
}

.sec-332 {
  padding: 80px 40px;
  border-radius: 10px;
  margin: 60px 0px 20px 0px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  display: flex;
  justify-content: center;
}
.overlay-vd {
  position: relative; /* Changed to relative */
  z-index: 100; /* Ensure the overlay is above the video */
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.video-container-vd video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto; /* Adjusted to maintain aspect ratio */
  object-fit: cover;
}

.ui-uiverse {
  --duration: 7s;
  --easing: linear;
  --c-color-1: rgba(255, 163, 26, 0.7);
  --c-color-2: #1a23ff;
  --c-color-3: #e21bda;
  --c-color-4: rgba(255, 232, 26, 0.7);
  --c-shadow: rgba(255, 223, 87, 0.5);
  --c-shadow-inset-top: rgba(255, 223, 52, 0.9);
  --c-shadow-inset-bottom: rgba(255, 250, 215, 0.8);
  --c-radial-inner: #ffd215;
  --c-radial-outer: #fff172;
  --c-color: #fff;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  outline: none;
  position: relative;
  cursor: pointer;
  border: none;
  display: table;
  border-radius: 24px;
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.5;
  color: black;
  background: radial-gradient(
    circle,
    var(--c-radial-inner),
    var(--c-radial-outer) 80%
  );
  box-shadow: 0 0 14px var(--c-shadow);
}

.ui-uiverse:before {
  content: "";
  pointer-events: none;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px;
  box-shadow: inset 0 3px 12px var(--c-shadow-inset-top),
    inset 0 -3px 4px var(--c-shadow-inset-bottom);
}

.ui-uiverse .ui-wrapper {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  border-radius: 24px;
  min-width: 132px;
  padding: 12px 0;
}
.top-20-modal {
  top: 20px;
}

.ui-uiverse .ui-wrapper span {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.ui-uiverse .ui-wrapper .ui-circle {
  position: absolute;
  left: 0;
  top: 0;
  width: 140px;
  height: 50px;
  border-radius: 50%;
  filter: blur(var(--blur, 8px));
  background: var(--background, transparent);
  transform: translate(var(--x, 0), var(--y, 0)) translateZ(0);
  animation: var(--animation, none) var(--duration) var(--easing) infinite;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-1,
.uiverse .wrapper .ui-circle.circle-9,
.uiverse .wrapper .ui-circle.circle-10 {
  --background: var(--c-color-4);
}

.ui-uiverse .ui-wrapper .ui-circle.ui-circle-3,
.ui-uiverse .ui-wrapper .ui-circle.circle-4 {
  --background: var(--c-color-2);
  --blur: 14px;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-5,
.ui-uiverse .ui-wrapper .ui-circle.circle-6 {
  --background: var(--c-color-3);
  --blur: 16px;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-2,
.ui-uiverse .ui-wrapper .ui-circle.circle-7,
.ui-uiverse .ui-wrapper .ui-circle.circle-8,
.ui-uiverse .ui-wrapper .ui-circle.circle-11,
.ui-uiverse .ui-wrapper .ui-circle.circle-12 {
  --background: var(--c-color-1);
  --blur: 12px;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-1 {
  --x: 0;
  --y: -40px;
  --animation: circle-1;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-2 {
  --x: 92px;
  --y: 8px;
  --animation: circle-2;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-3 {
  --x: -12px;
  --y: -12px;
  --animation: circle-3;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-4 {
  --x: 80px;
  --y: -12px;
  --animation: circle-4;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-5 {
  --x: 12px;
  --y: -4px;
  --animation: circle-5;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-6 {
  --x: 56px;
  --y: 16px;
  --animation: circle-6;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-7 {
  --x: 8px;
  --y: 28px;
  --animation: circle-7;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-8 {
  --x: 28px;
  --y: -4px;
  --animation: circle-8;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-9 {
  --x: 20px;
  --y: -12px;
  --animation: circle-9;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-10 {
  --x: 64px;
  --y: 16px;
  --animation: circle-10;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-11 {
  --x: 4px;
  --y: 4px;
  --animation: circle-11;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-12 {
  --blur: 14px;
  --x: 52px;
  --y: 4px;
  --animation: circle-12;
}

@keyframes circle-1 {
  33% {
    transform: translate(0px, 16px) translateZ(0);
  }

  66% {
    transform: translate(12px, 64px) translateZ(0);
  }
}

@keyframes circle-2 {
  33% {
    transform: translate(80px, -10px) translateZ(0);
  }

  66% {
    transform: translate(72px, -48px) translateZ(0);
  }
}

@keyframes circle-3 {
  33% {
    transform: translate(20px, 12px) translateZ(0);
  }

  66% {
    transform: translate(12px, 4px) translateZ(0);
  }
}

@keyframes circle-4 {
  33% {
    transform: translate(76px, -12px) translateZ(0);
  }

  66% {
    transform: translate(112px, -8px) translateZ(0);
  }
}

@keyframes circle-5 {
  33% {
    transform: translate(84px, 28px) translateZ(0);
  }

  66% {
    transform: translate(40px, -32px) translateZ(0);
  }
}

@keyframes circle-6 {
  33% {
    transform: translate(28px, -16px) translateZ(0);
  }

  66% {
    transform: translate(76px, -56px) translateZ(0);
  }
}

@keyframes circle-7 {
  33% {
    transform: translate(8px, 28px) translateZ(0);
  }

  66% {
    transform: translate(20px, -60px) translateZ(0);
  }
}

@keyframes circle-8 {
  33% {
    transform: translate(32px, -4px) translateZ(0);
  }

  66% {
    transform: translate(56px, -20px) translateZ(0);
  }
}

@keyframes circle-9 {
  33% {
    transform: translate(20px, -12px) translateZ(0);
  }

  66% {
    transform: translate(80px, -8px) translateZ(0);
  }
}

@keyframes circle-10 {
  33% {
    transform: translate(68px, 20px) translateZ(0);
  }

  66% {
    transform: translate(100px, 28px) translateZ(0);
  }
}

@keyframes circle-11 {
  33% {
    transform: translate(4px, 4px) translateZ(0);
  }

  66% {
    transform: translate(68px, 20px) translateZ(0);
  }
}

@keyframes circle-12 {
  33% {
    transform: translate(56px, 0px) translateZ(0);
  }

  66% {
    transform: translate(60px, -32px) translateZ(0);
  }
}

.bottom-right-pop {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}
.card-logo-top-l {
  /* background: linear-gradient(-45deg, #020c23, #020c23); */
  margin-top: 10px;

  border-radius: 0px;
}
.card-vd-top-m {
  padding: 5px;
  background: linear-gradient(-45deg, #020c23, #020c23); /* color: black; */
  margin: 5px;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.card-vd-top-m img {
  width: 100px;
  border-radius: 5px;
}
.video-container.full-width > div {
  max-width: 100%;
}
.card-vd-top-m.active-video-landing {
  background-image: linear-gradient(90deg, #c9fadb, #8bbffe);
}
.card-vd-top-m.active-video-landing .ant-typography {
  color: black;
  font-weight: 500;
}
.bottom-left-popup .my-lbutton {
  position: relative;
  z-index: 30;
  padding: 3px 7px;
}
.landing-logo-no-10 .my-lbutton {
  padding: 5px 10px;
}

.loader-video-img {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.loader-video-img img {
  width: 100px;
  height: 100px;

  animation: blinkImg 1s infinite alternate; /* Using the 'blink' animation */
}

/* Keyframes for the blink animation */
@keyframes blinkImg {
  0% {
    opacity: 1; /* Fully visible */
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);

    opacity: 0; /* Fully transparent */
  }
}
.testing-animation-cads .card-vd-top-m {
  color: white;
  padding: 15px;
  max-width: 350px;
}

.testing-animation-cads1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.show-mobile {
  display: none;
}

/* Slow motion button */
#slowmo-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  border: 0;
  font-size: 1rem;
  background: #fedc2a;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.275s;
}

#slowmo-toggle span {
  display: block;
  font-weight: normal;
}

#slowmo-toggle:hover,
#slowmo-toggle:focus {
  background: white;
  color: #fedc2a;
}

/* When slow motion is activated */
.is-slowmo + .custom-device .open-main-nav .menu-icon,
.is-slowmo + .custom-device .open-main-nav .menu-icon:before,
.is-slowmo + .custom-device .open-main-nav .menu-icon:after,
.is-slowmo + .custom-device .custom-main-nav,
.is-slowmo + .custom-device .custom-main-nav::before,
.is-slowmo + .custom-device .custom-main-nav a {
  transition-duration: 3s;
}

.is-slowmo + .custom-device .custom-main-nav li:nth-child(1) a {
  transition-delay: 1750ms;
}

.is-slowmo + .custom-device .custom-main-nav li:nth-child(2) a {
  transition-delay: 2250ms;
}

.is-slowmo + .custom-device .custom-main-nav li:nth-child(3) a {
  transition-delay: 2750ms;
}

.is-slowmo + .custom-device .custom-main-nav li:nth-child(4) a {
  transition-delay: 3250ms;
}

.is-slowmo + .custom-device .custom-main-nav li:nth-child(5) a {
  transition-delay: 3750ms;
}

/* Main menu positioning */
.custom-main-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  /* background: #FFF; */
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all 0.375s;
}

.custom-main-nav.is-open {
  opacity: 1;
  z-index: 100;
  visibility: visible;
}

/* Yellow band effect */
.custom-main-nav::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -15px;
  background-image: linear-gradient(90deg, #c9fadb, #8bbffe);

  transform-origin: 0 0;
  transform: skew(-14deg) translateX(-120%);
  transition: all 0.275s 0.1s;
}

.custom-main-nav.is-open::before {
  transform: skew(-14deg) translateX(0);
}

/* Skewing effect on menu links */
.custom-main-nav ul {
  display: inline-flex;
  flex-direction: column;
  height: 93%; /* Should be 100%, but we have a notice message :D */
  align-items: flex-end;
  justify-content: center;
  transform: translateX(-18%) skew(-16deg);
}

.custom-main-nav li {
  display: block;
  margin: 0.5rem 0;
  text-align: right;
  transform: skew(16deg);
}

/* Apparition effect on links */
.custom-main-nav a {
  opacity: 0;
  transform: translateY(-10px);
}

.custom-main-nav.is-open a {
  opacity: 1;
  transform: translateY(0);
}

/* Decoration */
.custom-main-nav ul,
.custom-main-nav li {
  list-style: none;
  padding: 0;
}

.custom-main-nav a {
  display: block;
  padding: 12px 0;
  color: white;
  font-size: 1.4em;
  text-decoration: none;
  font-weight: bold;
}

/* Burger Style */
.open-main-nav {
  position: absolute;
  top: 15px;
  padding-top: 20px;
  right: 15px;
  z-index: 1000;
  background: none;
  border: 0;
  cursor: pointer;
}

.open-main-nav:focus {
  outline: none;
}

.menu-icon {
  position: relative;
  display: block;
  width: 28px;
  height: 4px;
  margin: 0 auto;
  background: white;
  transform: skew(5deg);
  transition: all 0.275s;
}
.menu-text {
  color: white;
}
.menu-icon:after,
.menu-icon:before {
  content: "";
  display: block;
  height: 100%;
  background: white;
  transition: all 0.275s;
}

.menu-icon:after {
  transform: translateY(-12px) translateX(-2px) skew(-20deg);
}

.menu-icon:before {
  transform: translateY(-16px) skew(-10deg);
}

/* Toggle State part */
.is-open .menu-icon {
  transform: skew(5deg) translateY(-8px) rotate(-45deg);
}

.is-open .menu-icon:before {
  transform: translateY(0px) skew(-10deg) rotate(75deg);
}

.is-open .menu-icon:after {
  transform: translateY(-12px) translateX(10px) skew(-20deg);
  opacity: 0;
}

/* Notice */
.custom-notice {
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  padding: 20px;
  background: #f2f2f2;
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  z-index: 100;
  text-align: center;
}

.custom-notice strong {
  font-weight: 700;
}

.custom-notice a {
  padding: 2px 3px;
  background: #fedc2a;
  text-decoration: none;
}
.video-container.full-height > div {
  width: 100% !important;
  margin: 0 auto;
  height: 100% !important;
  /* max-height: 300px; */
  border-radius: 10px;
  padding: 20px 20px 2px 20px;
}
.video-container.full-height video {
  border-radius: 10px !important;
}
.testing-animation-cads {
  position: fixed;
  bottom: 60px; /* Adjust the distance from the bottom */
  left: 10px; /* Adjust the distance from the left */
  z-index: 10;
}
.card-vd-top-m.no-bg-card {
  background: linear-gradient(-45deg, #020c23, #020c23); /* color: black; */
  padding: 30px;
}
.landing-logo-no-10 .ant-space-item {
  color: white !important;
  font-weight: 500;
}
.landing-logo-no-10 .ant-space-item .anticon-down{
  
  margin-right: 15px;
}
.landing-logo-no-10 b{
  font-weight: 500;
}
@media screen and (max-width: 680px) {
  .my-lheading {
    font-size: 3rem;
  }
  .hide-mobile {
    display: none;
  }
  .show-mobile {
    display: flex;
  }
  .video-container.full-height > div {
    padding: 0px;
  }
}
