.ant-slider .ant-slider-rail {
  background-color: #a4a4a4;
}
.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #bedbff;
}
.ant-slider .ant-slider-track {
  background-color: #bedbff;
}
.slider-bx-c {
  display: flex;
  align-items: flex-start;
}
.ant-input-number .ant-input-number-input {
  background-color: #0c0f14;
}
.ant-input-number .ant-input-number-input {
  color: white;
}

.slider-image-si img {
  height: 300px;
  margin: 0 auto;
  object-fit: contain;
  width: 100%;
}
.sumilation-main-d {
  padding: 40px 10px 20px 10px;
  height: 100vh;
  overflow-y: auto !important;
  color: white;
}
.chat-video-right {
  height: 85vh;
}
.simu-card-t-i {
  margin-bottom: 10px;
  font-size: 18px;
}
.simu-card-t-i svg {
  font-size: 30px;
  padding: 3px;
  margin-right: 10px;
}

.form-pf .simu-main-cards-c .hover-card-b {
  background: linear-gradient(-45deg, #141414 0%, #090c1099 100%);
  color: black;
  box-shadow: 4px 5px black;
  color: white;
  border-color: black;
}
.form-pf .simu-main-cards-c .hover-card-b:active {
  box-shadow: 4px 5px transparent;
}
.space-b-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ling-text-header {
  text-decoration: none;
  color: white;
  margin: 0px 5px;
  font-weight: 500;
}
.ling-text-header:hover {
  transition: 0.5s;
  color: #bedbff;
}

.live-e-card {
  background: transparent;
  box-shadow: 0px 8px 28px -9px rgba(0, 0, 0, 0.45);
  position: relative;

  height: 300px;
  border-radius: 10px;
  overflow: hidden;
}
.live-e-card .ant-typography {
  font-size: 18px;
  color: white;
  margin: 0px 5px;
}

.live-wave {
  position: absolute;
  width: 540px;
  height: 700px;
  opacity: 0.6;
  left: 0;
  top: 0;
  margin-left: -50%;
  margin-top: -70%;
}
.green-wave.live-wave {
  background: linear-gradient(744deg, #1aac00, #20a200 60%, #00eb76);
}
.orange-wave.live-wave {
  background: linear-gradient(744deg, #ac9500, orange 60%, #8d7b08);
}

.yellow-wave.live-wave {
  background: linear-gradient(744deg, #717171, yellow 60%, rgb(131, 114, 2));
}

.live-icon {
  font-size: 50px;
  margin-bottom: 10px;
}

.live-infotop {
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: 5.6em;
  left: 0;
  right: 0;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.live-name {
  font-size: 14px;
  font-weight: 100;
  position: relative;
  top: 1em;
  bottom: 0;
  text-transform: lowercase;
}

.live-wave:nth-child(2),
.live-wave:nth-child(3) {
  top: 210px;
}

.live-playing .live-wave {
  border-radius: 40%;
  animation: wave 3000ms infinite linear;
}

.live-wave {
  border-radius: 40%;
  animation: wave 55s infinite linear;
}

.live-playing .live-wave:nth-child(2) {
  animation-duration: 4000ms;
}

.live-wave:nth-child(2) {
  animation-duration: 50s;
}

.live-playing .live-wave:nth-child(3) {
  animation-duration: 5000ms;
}

.live-wave:nth-child(3) {
  animation-duration: 45s;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.bottom-icnx-s {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.time-counter-r {
  display: flex;
  justify-content: center;
}
.time-counter-r span {
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.512);
  border-radius: 5px;
}

.c3-icons-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  z-index: 999;
  align-items: center;
}

.c3-icons-container .c3-svg {
  stroke: black;
  color: white;
  font-size: 20px;
  margin-left: 10px;
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.c3-icons-container .c3-svg:hover {
  transform: scale(1.1);
  stroke: black;
  cursor: pointer;
}

.c3-icons-container .c3-twitter {
  fill: white;
}

.c3-icons-container .c3-twitter:hover {
  fill: black;
  stroke: none;
}
.c3-icons-container .ant-tag {
  cursor: pointer;
}

.c3-logo {
  width: 50px;
  padding: 1rem;
}

.c3-logo .c3-svg {
  fill: white;
}

.c3-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-weight: bold;
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
  color: white;
}
.c3-title span {
  font-size: 13px;
}
.c3-box {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: rgba(66, 66, 66, 0.349) 5px 0px 10px 0px inset;
  transition: all 0.5s ease-in-out;
  display: grid;
  place-content: center;
  padding: 2rem 2rem 3rem 2rem;
  border: 1px solid black;
}

.c3-box .c3-content {
  position: relative;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.5s ease-in-out 0.3s;
}

.c3-box .c3-content .c3-box-title {
  display: block;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.content-card-mainc {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  height: 100%;
}

.c3-box .c3-content .c3-box-text {
  color: rgba(255, 255, 255, 0.738);
  font-size: 0.8rem;
}

.c3-box:hover {
  right: 0;
  top: 0;
  border-radius: 0;
  border: 1px solid transparent;
  box-shadow: rgba(66, 66, 66, 0) 5px 0px 10px 0px inset;
}

.c3-box:hover .c3-content {
  transform: translate(0, 0);
  opacity: 1;
}

.c3button {
  cursor: pointer;
  font-weight: 700;
  font-family: Helvetica, "sans-serif";
  transition: all 0.2s ease-in;
  padding: 10px 20px;
  border-radius: 100px;
  background: linear-gradient(-45deg, white 0%, white 100%);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin: 0px 10px;
}
.active-live-c.c3button {
  background: linear-gradient(-45deg, #b3cae7 0%, #bedbff 100%);
}
.c3button:hover {
  cursor: pointer;
}

.c3button > svg {
  width: 34px;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}

.c3button:hover svg {
  transform: translateX(5px);
}

.c3button:active {
  transform: scale(0.95);
}

.s3-tabs-c {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}
.flex-end-top-c {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.flex-end-top-c .hover-card-b svg {
  margin-left: 5px;
  margin-right: 5px;
}
.flex-end-top-c .hover-card-b:hover svg {
  margin-left: 10px;
  margin-right: 0px;
  transition: 0.5s;
}
.together-fields-c {
  display: flex;
}
.date-title-c {
  margin-bottom: 60px;
}
.date-title-c h3 {
  color: #fbc97e;
  background-color: rgb(255 255 255 / 10%);
  display: inline;
  padding: 10px;
  position: relative;
  text-align: center;
  margin: 0 auto;
  border-radius: 5px;
}
.date-title-c h3 svg {
  margin-right: 10px;
  color: #bedbff;
  /* position: absolute; */
  /* left: 10px; */
}
.date-title-c .ant-row {
  margin-top: 20px;
}

.chat-video-right {
  border: 1px solid #bedbff;
  padding: 20px;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  align-items: flex-start;
  background-color: black;
}
.chat-close-icn svg {
  position: absolute;
  right: 10px;
  top: 10px;
}
.chat-close-icn svg:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.4s;
  color: #bedbff;
  background-color: black;
}
.ant-col {
  transition: all 0.4s ease-in-out;
}
.chat-open-icn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 30px;
  padding: 0px 5px;
  border-radius: 5px;
  color: #bedbff;
  background-color: black;
}
.chat-open-icn:hover {
  background: linear-gradient(-45deg, #bedbff 0%, #4b6c94 100%);

  color: black;
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.4s;
}
.chat-video-right .c3button {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
}
.chat-bx-input-c .ant-input {
  background-color: black;
  border: 1px solid #bedbff;
  color: white;
  border-radius: 5px;
}
.chat-bx-input-c .ant-input::placeholder {
  color: rgb(132, 132, 132);
}
.advertize-con {
  padding: 10px;
  margin: 10px 0px;
  background-color: rgba(255, 255, 255, 0.1);
}
.active-goals-tc256 {
  height: 70vh;
  overflow-y: auto;
}

.ant-picker {
  color: white !important;

  background-color: transparent;
  border-radius: 0px;
}
.ant-picker .ant-picker-input > input {
  color: white !important;
}
.ant-picker .ant-picker-suffix {
  color: white !important;
}
.ant-picker .ant-picker-input > input::placeholder {
  color: white !important;
}
.date-session-c {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.date-session-c2 {
  margin-top: 20px;
}
.ant-input-number {
  color: white !important;
  background-color: transparent;
  border-radius: 0px;
}
.ant-input-number::placeholder {
  color: white;
}
.cell-call-img-ac {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  z-index: 20;
}
.call-icon-c {
  color: lime;
  position: absolute;
  right: 40px;
  top: 30px;
  z-index: 30;
  cursor: pointer;
}
.call-icon-c:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.audio-hidden-c {
  transform: scale(0);
}
.animated-bg-live {
  font-size: 25px;
  animation: colorChange 5s infinite;
}
@keyframes colorChange {
  0% {
    color: lime;
  }
  50% {
    color: white;
  }
  100% {
    color: lime;
  }
}
