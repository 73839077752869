.biling-m-c {
  display: flex;
  margin-top: 20px;
}
.biling-m-c1 {
  width: 330px;
}
.biling-m-c3 {
  display: flex;
  justify-content: center;
  margin: 5px 0px;
}

.billing-page-main-cb{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 20px auto;
}
.billing-page-main-cb p{
  text-align: justify;
}
.price-b-m{
    margin-left: 10px;
    color: #bedbff;
    font-size: 20px;
}
.bottom-txt-sli{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.bottom-txt-sli b{
    font-size: 11px;
    color: rgb(174, 174, 174);
    margin-top: -5px;
    
}
.biling-m-ci1{
  background-color: black;
  padding: 20px 20px;
  border-radius: 10px;
}
.biling-m-c2{
  margin-left: 5px;
  display: flex;
  align-items: center;
  width: 100%;
}
.biling-m-ci1 .ant-slider-rail {
  height: 10px;
}
.biling-m-ci1 .ant-slider-track{
  height: 10px;

}
.biling-m-ci1 .ant-slider .ant-slider-handle::after{
width: 20px;
height: 20px;
top: -3px;
}
.biling-m-c2.biling-m-ci1{
  flex-direction: column;
}
.biling-m-c2.biling-m-ci1 > span {
  font-size: 13px;
  margin-bottom: 5px;
  color: lime;

}
.ant-input-number .ant-input-number-input{
  color: lime !important;
  font-weight: 600;
}
