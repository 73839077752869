.public-video-card {
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  background-color: black;
  padding: 10px;
  border-radius: 10px;
}
.public-video-card img {
  height: 300px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.public-page-c {
  height: 100vh;
  overflow-y: scroll;
}
.bottom-right-c {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.public-top-c {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
.public-top-c b {
  padding: 5px 10px;
  color: white;
  background-color: #1d29389d;

  text-align: center;
  border-radius: 5px;
  font-weight: 600;
}
.public-top-c2 {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding: 3px;
  color: white;
  background-color: #1d29389d;
  text-align: center;
  font-size: 12px;
}
.right-arrow-public {
  position: relative;
  z-index: 10;
  color: black;
  background: linear-gradient(-45deg, #bedbff 0%, #cafbdc 100%);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-arrow-public:hover {
  background: linear-gradient(-45deg, #63a7fb 0%, #648bbb 100%);

  color: white;
  cursor: pointer;
  transition: 0.4s;
}
.right-arrow-public svg {
  margin-left: 2px;
}
